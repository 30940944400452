import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription, combineLatest } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-table-sales-quartil',
  templateUrl: './table-sales-quartil.component.html',
  styleUrls: ['./table-sales-quartil.component.scss']
})
export class TableSalesQuartilComponent implements OnInit, OnDestroy {

  extraColumnIndex = -1;
  idStation!: string;
  market = '';
  productType = 1;
  productType$!: Subscription;
  variable = 'sales'
  variable$!: Subscription;
  daysOptimumPx = 180;
  daysOptimumPx$!: Subscription;
  private subscriptions: Subscription = new Subscription();
  private chart: any;
  reqst = 0;
  rol;
  rowsCurrent: any;
  rowsLength: number;
  rowsRecord: any;
  val: any;
  showSplitZones = false;
  showSubsubdivision = 0;
  showSubsubdivision$!: Subscription;
  showSubdivision = 0;
  showSubdivision$!: Subscription;
  simulation = false;
  simulationData!: any;
  simPrice!: string | null;
  simDifPrice!: string | null;
  splitZones = false;
  type!: string;


  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.subscriptions.add(
      combineLatest([
        this.sharedService.sharedVariablePxOpt,
        this.sharedService.sharedProduct,
        this.sharedService.sharedDaysOptimumPx,
        this.sharedService.sharedShowSubdivison,
        this.sharedService.sharedShowSubsubdivison
      ]).subscribe(([variable, productType, daysOptimumPx, showSubdivision, showSubsubdivision]) => {
        let shouldUpdate = false;

        if (this.variable !== variable) {
          this.variable = variable;
          shouldUpdate = true;
        }

        if (this.productType !== productType) {
          this.productType = productType;
          shouldUpdate = true;
        }

        if (this.daysOptimumPx !== daysOptimumPx) {
          this.daysOptimumPx = daysOptimumPx;
          shouldUpdate = true;
        }

        if (this.showSubdivision !== showSubdivision) {
          this.showSubdivision = showSubdivision;
        }

        if (this.showSubsubdivision !== showSubsubdivision) {
          this.showSubsubdivision = showSubsubdivision;
        }

        if (shouldUpdate && this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      })
    );
    this.idStation = localStorage.getItem('idStation');
  }

  ngOnInit(): void {
    this.getSalesQuartilSplitZones();
    this.getTableSalesQuartil();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getRowHeight(row: any) {
    return row.height;
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  changeSplitZones() {
    this.splitZones = !this.splitZones;
    this.showSubdivision = this.showSubdivision === 1 ? 0 : 1;
    this.sharedService.nextShowSubdivison = this.showSubdivision;
    this.getTableSalesQuartil();
  }

  changeSubSplitZones() {
    this.showSubdivision = this.showSubdivision === 1 ? 0 : 1;
    this.showSubsubdivision = this.showSubsubdivision === 0 ? 1 : 0;
    this.sharedService.nextShowSubsubdivison = this.showSubsubdivision;
    this.sharedService.nextShowSubdivison = this.showSubdivision;
    this.showSubdivision = this.showSubdivision === 1 ? 0 : 1;
    this.getTableSalesQuartil();
  }

  getSalesQuartilSplitZones() {
    this.gasprecioService.getSalesQuartilSplitZones(this.productType, this.daysOptimumPx, this.showSubsubdivision).subscribe((data: any) => {
      this.showSplitZones = data;
    })
  }

  prepareSimulation(index: number, market: string, type: 'diff' | 'sale'): void {
    if (!this.simulation) {
      this.removeAt(this.extraColumnIndex + 1);
      return;
    }

    this.extraColumnIndex = index;
    this.type = type;
    const element = {
      idx: index + 100,
      diff_price_competitors: '-',
      earning: '-',
      flag_obs: null,
      margin: '-',
      n_obs: '-',
      quotient: '-',
      sales: '-',
      sales_change: '-',
      sales_pct: '-',
      selling_price: '-',
      has_subdivisions: false,
      has_subsubdivisions: false,
      market: `sim_${market}`,
      flag_actual: false,
      showExpand: false
    };
    if (type === 'sale') this.simPrice = this.rowsCurrent[index].selling_price;
    else this.simDifPrice = this.rowsCurrent[index].diff_price_competitors;
    this.insertAt(index + 1, element);
  }



  getTableSalesQuartil() {
    this.reqst = 0;
    this.rowsCurrent = [];
    this.rowsRecord = [];
    setTimeout(() => {
      this.gasprecioService.getTableSalesQuartil(this.productType, this.variable, this.daysOptimumPx ,this.showSubdivision, this.showSubsubdivision).subscribe((data: any) => {
        if (data['record']) {
          this.rowsLength = (73 / data['record'].length);
          data['record'].map((element: any, index) => {
            if (element['flag_actual']) {
              this.market = element['market'];
            }
            data['current'][index]['showExpand'] = element['n_obs'] >= 60;
            element['showExpand'] = element['n_obs'] >= 60 ? true : false;
            for (const key in element) {
              if (element.hasOwnProperty(key) && element[key] === null) {
                  element[key] = '-';
              }
              if (key === "sales_pct") element[key] = element[key] * 100
            }
          });

          data['current'].map((element: any, _: number) => {
            if (element['flag_actual']) {
              this.market = element['market'];
            }
            for (const key in element) {
              if (element.hasOwnProperty(key) && element[key] === null) {
                  element[key] = '-';
              }
            }
          });
          // this.rowsFcst = data['current'];
          this.rowsCurrent = data['current'];
          this.rowsRecord = data['record'];
        }
        this.reqst = 1;
      });
    }, 300);
  }

  px(price: string): void {
    this.reqst = 0;
    this.gasprecioService.showLoader('Generando Simulación', 'Los datos tardan un poco en generarse, aguarda un momento...');
    this.gasprecioService.getPxOptimo(price, this.type, this.productType, this.idStation).subscribe({
      next: (res) => {
        this.simulationData = res.data.markets.simulation;
        this.rowsCurrent[this.extraColumnIndex + 1] = this.simulationData;
        this.gasprecioService.displayMessage('success', '¡Simulación hecha con éxito!');
        this.reqst = 1;
      },
      error: (_: HttpErrorResponse) => {
        this.gasprecioService.displayMessage('error', 'Hubo un error al hacer la simulación. Intenta de nueevo más tarde.')
        this.reqst = 1;
      }
    })
  }

  insertAt(index: number, value: any): void {
    this.rowsRecord.splice(index, 0, value);
    this.rowsCurrent.splice(index, 0, { ...value, simulation: true });
  }

  removeAt(index: number): void {
    this.rowsRecord.splice(index, 1);
    this.rowsCurrent.splice(index, 1);
    this.simPrice = null;
    this.simDifPrice = null;
    this.simulationData = null;
  }

}