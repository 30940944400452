<div class="row" style="margin:10px;" *ngIf="reqst === 0">
    <app-table-loader style="width: 100%;"></app-table-loader>
</div>
<table *ngIf="reqst === 1">
    <thead>
        <tr>
            <th style="width: 27%;padding: 0 1px !important;" class="text-right">
                <span style="font-size: 15px;text-decoration: underline;margin-right: 20px;">
                    Últimos {{daysOptimumPx}} días
                </span>
            </th>
            <th *ngFor="let row of rowsRecord"
            [ngClass]="{ 'low': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
            'medium-l': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
            'medium-h': (row.market=== 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
            'high': (row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-all': !row.flag_actual}"
            [ngStyle]="{ 'width': (73 / rowsRecord.length) + '%' }">
            <span
            *ngIf="row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b'">Baja</span>
        <span
            *ngIf="row.market === 'sim_low' || row.market === 'sim_low_inferior'  || row.market === 'sim_low_inferior_a'  || row.market === 'sim_low_inferior_b' || row.market === 'sim_low_superior' || row.market === 'sim_low_superior_a' || row.market === 'sim_low_superior_b'">Simulación</span>
        <span
            *ngIf="row.market === 'medium_low'|| row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b'">Media
            Baja</span>
        <span
            *ngIf="row.market === 'sim_medium_low'|| row.market === 'sim_medium_low_inferior' || row.market === 'sim_medium_low_inferior_a' || row.market === 'sim_medium_low_inferior_b' || row.market === 'sim_medium_low_superior' || row.market === 'sim_medium_low_superior_a' || row.market === 'sim_medium_low_superior_b'">Simulación</span>
        <span
            *ngIf="row.market=== 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b'">Media
            Alta</span>
        <span
            *ngIf="row.market=== 'sim_medium_high' || row.market === 'sim_medium_high_inferior' || row.market === 'sim_medium_high_inferior_a' || row.market === 'sim_medium_high_inferior_b' || row.market === 'sim_medium_high_superior' || row.market === 'sim_medium_high_superior_a' || row.market === 'sim_medium_high_superior_b'">Simulación</span>
        <span
            *ngIf="row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b'">Alta</span>
        <span
            *ngIf="row.market=== 'sim_high' || row.market === 'sim_high_inferior' || row.market === 'sim_high_inferior_a' || row.market === 'sim_high_inferior_b' || row.market === 'sim_high_superior' || row.market === 'sim_high_superior_a' || row.market === 'sim_high_superior_b'">Simulación</span>
                <div style="display: inline-block;margin-left: 10px;" *ngIf="showSplitZones && row.has_subdivisions">
                    <epy-tooltip placement="bottom" (click)="changeSplitZones();">
                        <span class="difference" slot="trigger">
                            <i class="fa-solid icon-s" style="font-weight: bold;"
                                [ngStyle]="{ 'color':  (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual || (row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual ? 'gray' : '#00e07b' }"
                                [ngClass]=" {'fa-up-right-and-down-left-from-center': (showSubdivision === 0 && showSubsubdivision === 0), 'fa-down-left-and-up-right-to-center': (showSubdivision === 1 || showSubsubdivision === 1)}"
                                aria-hidden="true"></i>
                        </span>
                        {{(showSubdivision === 1 || showSubsubdivision === 1) ? 'Esconder Subdivisiones de Zona' : 'Mostrar Subdivisiones de Zona' }}
                    </epy-tooltip>
                </div>
                <div style="display: inline-block;margin-left: 10px;" *ngIf="showSubdivision === 1 || showSubsubdivision === 1 && row.has_subsubdivisions">
                    <epy-tooltip placement="bottom" (click)="changeSubSplitZones();">
                        <span class="difference" slot="trigger">
                            <i 
                                *ngIf="showSubdivision === 1 && !showSubsubdivision && row.has_subsubdivisions"
                                class="fa fa-add icon-s"
                                style="font-weight: bold;" aria-hidden="true">
                            </i>
                            <i 
                                *ngIf="!showSubdivision && showSubsubdivision"
                                class="fa fa-minus icon-s"
                                style="font-weight: bold;" aria-hidden="true">
                            </i>
                        </span>
                        {{(showSubdivision && !showSubsubdivision) ? 'Agregar subdivisión de Zona' : 'Quitar subdivisión de Zona' }} 
                    </epy-tooltip>
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    # días
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        # de observaciones de los últimos {{daysOptimumPx}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
            [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual,
            'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
            'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
            'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span style="display: flex;justify-content: center;align-items: center;column-gap: 10px;">
                    {{row.n_obs}}
                    <p title="Pocas observaciones" class="red" style="margin-bottom: 0;" [attr.data-letters]="'red'"
                        *ngIf="row.flag_obs === 1"></p>
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Precio de Venta
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Precio de venta promedio diario reportado a la CRE por estación de los últimos {{daysOptimumPx}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.selling_price !== '-'">
                    {{row.selling_price| number : '1.3-3':'es'}} €
                </span>
                <span *ngIf="row.selling_price === '-'">
                    {{row.selling_price}}
                </span>
            </td>
        </tr>
        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Dif Precio vs Comp.
                    </strong>
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        Promedio Diario de la diferencia de Precio de venta de tu estación vs tu competencia
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;font-weight: bold;color: white !important;">
                <span *ngIf="row.diff_price_competitors !== '-'" style="color: white !important">
                    <strong>
                        {{row.diff_price_competitors| number : '1.3-3':'es'}} €
                    </strong>
                </span>
                <span *ngIf="row.diff_price_competitors === '-'" style="color: white !important">
                    <strong>
                        {{row.diff_price_competitors}}
                    </strong>
                </span>
            </td>
        </tr>
        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Indice de Precios
                    </strong>
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es <
                            1.0 significa que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el
                            de tus competidores.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;font-weight: bold;color: white !important;">
                <span *ngIf="row.quotient !== '-'">
                    <strong>
                        {{row.quotient| number : '1.3-3':'es'}}
                    </strong>
                </span>
                <span *ngIf="row.quotient === '-'">
                    <strong>
                        {{row.quotient}}
                    </strong>
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Margen Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de margen de ganancia diario calculado por estación (Precio de Venta vs Precio de
                        Compra) de los últimos {{daysOptimumPx}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.margin !== '-'">
                    {{row.margin| number : '1.3-3':'es'}} €
                </span>
                <span *ngIf="row.margin === '-'">
                    {{row.margin}}
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Venta Litros
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Tendencia general de los últimos {{daysOptimumPx}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.sales !== '-'">
                    {{row.sales| number : '1.0-0':'es'}}
                </span>
                <span *ngIf="row.sales === '-'">
                    {{row.sales}}
                </span>


            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Ganancia Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de volumen de venta en litros multiplicado en margen bruto de los últimos {{daysOptimumPx}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
            [ngClass]="{ 'low-bottom':(row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 'medium-l-bottom': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,'medium-h-bottom': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,'high-bottom':(row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-bottom': !row.flag_actual }">
                <span *ngIf="row.earning !== '-'">
                    {{row.earning| number : '1.0-0':'es'}} €
                </span>
                <span *ngIf="row.earning === '-'">
                    {{row.earning}}
                </span>

            </td>
        </tr>
        <tr style="height:10px">
            <td *ngFor="let row of rowsRecord; let index = index"
                style="height: 10px !important;color: white !important;font-size: 1px !important;">
                &nbsp;
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    <i class="fa-solid fa-star icon"></i> Cambio Venta lts
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Diferencia de Litros Vendidos Promedio de la zona actual con respecto a las otras zonas
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index" style="border-bottom: none !important;"
            [ngClass]="{ 'low':(row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 'medium-l': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,'medium-h': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,'high':(row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row.sales_change !== '-'">
                    {{row.sales_change| number : '1.0-0':'es'}}
                </span>
                <span *ngIf="row.sales_change === '-'">
                    {{row.sales_change}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Cambio Venta %
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Diferencia de % de Litros Vendidos Promedio de la zona actual con respecto a las otras zonas
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index" style="border-top: none !important;"
            [ngClass]="{ 'low':(row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 'medium-l': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,'medium-h': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,'high':(row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-bottom': !row.flag_actual}">
                <span *ngIf="row.sales_pct !== '-'">
                    {{row.sales_pct| number : '1.0-0':'es'}} %
                </span>
                <span *ngIf="row.sales_pct === '-'">
                    {{row.sales_pct}}
                </span>
            </td>
        </tr>
    </tbody>
</table>

<table *ngIf="reqst === 1" style="margin-top: 10px;">
    <thead>
        <tr>
            <th style="width: 27%;padding: 0 1px !important;" class="text-right">
                <span style="font-size: 15px;text-decoration: underline;margin-right: 20px;">
                    Actual
                </span>
            </th>
            <th *ngFor="let row of rowsCurrent"
            [ngClass]="{ 'low': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
            'medium-l':  (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
            'medium-h':  (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
            'high': (row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-all': !row.flag_actual}"
            [ngStyle]="{'width': (73 / rowsCurrent?.length) + '%' }">
            <span
            *ngIf="row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b'">Baja</span>
        <span
            *ngIf="row.market === 'sim_low' || row.market === 'sim_low_inferior'  || row.market === 'sim_low_inferior_a'  || row.market === 'sim_low_inferior_b' || row.market === 'sim_low_superior' || row.market === 'sim_low_superior_a' || row.market === 'sim_low_superior_b'">Simulación</span>
        <span
            *ngIf="row.market === 'medium_low'|| row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b'">Media
            Baja</span>
        <span
            *ngIf="row.market === 'sim_medium_low'|| row.market === 'sim_medium_low_inferior' || row.market === 'sim_medium_low_inferior_a' || row.market === 'sim_medium_low_inferior_b' || row.market === 'sim_medium_low_superior' || row.market === 'sim_medium_low_superior_a' || row.market === 'sim_medium_low_superior_b'">Simulación</span>
        <span
            *ngIf="row.market=== 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b'">Media
            Alta</span>
        <span
            *ngIf="row.market=== 'sim_medium_high' || row.market === 'sim_medium_high_inferior' || row.market === 'sim_medium_high_inferior_a' || row.market === 'sim_medium_high_inferior_b' || row.market === 'sim_medium_high_superior' || row.market === 'sim_medium_high_superior_a' || row.market === 'sim_medium_high_superior_b'">Simulación</span>
        <span
            *ngIf="row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b'">Alta</span>
        <span
            *ngIf="row.market=== 'sim_high' || row.market === 'sim_high_inferior' || row.market === 'sim_high_inferior_a' || row.market === 'sim_high_inferior_b' || row.market === 'sim_high_superior' || row.market === 'sim_high_superior_a' || row.market === 'sim_high_superior_b'">Simulación</span>
                <div style="display: inline-block;margin-left: 10px;" *ngIf="showSplitZones && row.has_subdivisions">
                    <epy-tooltip placement="bottom" (click)="changeSplitZones();">
                        <span class="difference" slot="trigger">
                            <i class="fa-solid icon-s" style="font-weight: bold;"
                                [ngStyle]="{ 'color':  (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual || (row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual ? 'gray' : '#00e07b' }"
                                [ngClass]=" {'fa-up-right-and-down-left-from-center': (showSubdivision === 0 && showSubsubdivision === 0), 'fa-down-left-and-up-right-to-center': (showSubdivision === 1 || showSubsubdivision === 1)}"
                                aria-hidden="true"></i>
                        </span>
                        {{(showSubdivision === 1 || showSubsubdivision === 1) ? 'Esconder Subdivisiones de Zona' : 'Mostrar Subdivisiones de Zona' }}
                    </epy-tooltip>
                </div>
                <div style="display: inline-block;margin-left: 10px;" *ngIf="showSubdivision === 1 || showSubsubdivision === 1 && row.has_subsubdivisions">
                    <epy-tooltip placement="bottom" (click)="changeSubSplitZones();">
                        <span class="difference" slot="trigger">
                            <i
                                *ngIf="showSubdivision === 1 && !showSubsubdivision && row.has_subsubdivisions"
                                class="fa fa-add icon-s"
                                style="font-weight: bold;" aria-hidden="true">
                            </i>
                            <i
                                *ngIf="!showSubdivision && showSubsubdivision"
                                class="fa fa-minus icon-s"
                                style="font-weight: bold;" aria-hidden="true">
                            </i>
                        </span>
                        {{(showSubdivision && !showSubsubdivision) ? 'Agregar subdivisión de Zona' : 'Quitar subdivisión de Zona' }} 
                    </epy-tooltip>
                </div>
            </th>
        </tr>
    </thead>
    <tbody>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Precio de Venta
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Precio de venta diario reportado a la CRE por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.selling_price !== '-' && !row?.simulation">
                    $ {{row.selling_price| number : '1.3-3'}}
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;" (click)="simulation = !simulation; prepareSimulation(index, row.market, 'sale')">
                        <span class="difference" slot="trigger">
                            <i class="fa-solid fa-calculator icon-sim transparent-icon" aria-hidden="true" [style.color]="simulation ? '#CB317D' : ''"></i>
                        </span>
                        {{ !simulation ? 'Simular Zona' : 'Quitar simulación' }}
                    </epy-tooltip>
                </span>
                <span *ngIf="row.selling_price !== '-'">
                    {{row.selling_price| number : '1.3-3':'es'}} €
                </span>
                <span *ngIf="row.selling_price === '-'">
                    {{row.selling_price}}
                </span>
                <span *ngIf="row?.simulation && simPrice">
                    $ <input class="sim-input" type="text" [(ngModel)]="simPrice">
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;" (click)="px(simPrice)">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-play icon-sim" aria-hidden="true"></i>
                        </span>
                        Hacer Simulación por Precio de Venta
                    </epy-tooltip>
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    <i class="fa-solid fa-star icon"></i>Cambio de Precio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de litros diarios vendidos por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                style="border-top: none !important;border-bottom: none !important;"
                [ngClass]="{ 'low':(row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 'medium-l': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,'medium-h': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,'high':(row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual}">
                <span *ngIf="row.price_change !== '-'">
                    {{row.price_change| number : '1.3-3':'es'}} €
                </span>
                <span *ngIf="row.price_change === '-'">
                    {{row.price_change}}
                </span>
            </td>
        </tr>

        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Dif Precio vs Comp.
                    </strong>
                    <epy-tooltip placement="top"
                        style="width: 18px;display: inline-block;text-align: left;color: white !important;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        Promedio Diario de la diferencia de Precio de venta de tu estación vs tu competencia
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;color: white !important;">
                <span *ngIf="row.diff_price_competitors !== '-' && !row?.simulation">
                    <strong>
                        $ {{row.diff_price_competitors| number : '1.2-2'}}
                        <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;" (click)="simulation = !simulation; prepareSimulation(index, row.market, 'diff')">
                            <span class="difference" slot="trigger">
                                <i class="fa-solid fa-calculator icon-sim transparent-icon" aria-hidden="true" [style.color]="simulation ? '#CB317D' : ''"></i>
                            </span>
                            {{ !simulation ? 'Simular Zona' : 'Quitar simulación' }}
                        </epy-tooltip>
                    </strong>
                </span>
                <span *ngIf="row.diff_price_competitors !== '-'" style="color: white !important">
                    <strong>
                        {{row.diff_price_competitors| number : '1.3-3':'es'}} €
                    </strong>
                </span>
                <span *ngIf="row.diff_price_competitors === '-'" style="color: white !important">
                    <strong>
                        {{row.diff_price_competitors}}
                    </strong>
                </span>
                <span *ngIf="row?.simulation && simDifPrice">
                    $ <input class="sim-input diff" type="text" [(ngModel)]="simDifPrice">
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;" (click)="px(simDifPrice)">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-play icon-sim" aria-hidden="true"></i>
                        </span>
                        Hacer Simulación por Diferencia de Precio
                    </epy-tooltip>
                </span>
            </td>
        </tr>
        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Indice de Precios
                    </strong>
                    <epy-tooltip placement="top"
                        style="width: 18px;display: inline-block;text-align: left;color: white !important;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es <
                            1.0 significa que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el
                            de tus competidores.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;color: white !important;">
                <span *ngIf="row.quotient !== '-'">
                    <strong>
                        {{row.quotient| number : '1.3-3':'es'}}
                    </strong>
                </span>
                <span *ngIf="row.quotient === '-'">
                    <strong>
                        {{row.quotient}}
                    </strong>
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Precio de Compra
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                    Precio Promedio de compra por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.supply_price !== '-'">
                    {{row.supply_price| number : '1.3-3':'es'}} €
                </span>
                <span *ngIf="row.supply_price === '-'">
                    {{row.supply_price}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Margen Prom Hoy
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Margen Promedio de hoy por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.margin !== '-'">
                    {{row.margin| number : '1.3-3':'es'}} €
                </span>
                <span *ngIf="row.margin === '-'">
                    {{row.margin}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Venta litros 7 días
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de litros semanales vendidos por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides': (row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 
                'medium-l-sides': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,
                'medium-h-sides': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,
                'high-sides': (row.market === 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.sales7 !== '-'">
                    {{row.sales7| number : '1.0-0':'es'}}
                </span>
                <span *ngIf="row.sales7 === '-'">
                    {{row.sales7}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Ganancia Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de volumen de venta en litros multiplicado en margen bruto.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
            [ngClass]="{ 'low-bottom':(row.market === 'low' || row.market === 'low_inferior'  || row.market === 'low_inferior_a'  || row.market === 'low_inferior_b' || row.market === 'low_superior' || row.market === 'low_superior_a' || row.market === 'low_superior_b') && row.flag_actual, 'medium-l-bottom': (row.market === 'medium_low' || row.market === 'medium_low_inferior' || row.market === 'medium_low_inferior_a' || row.market === 'medium_low_inferior_b' || row.market === 'medium_low_superior' || row.market === 'medium_low_superior_a' || row.market === 'medium_low_superior_b') && row.flag_actual,'medium-h-bottom': (row.market === 'medium_high' || row.market === 'medium_high_inferior' || row.market === 'medium_high_inferior_a' || row.market === 'medium_high_inferior_b' || row.market === 'medium_high_superior' || row.market === 'medium_high_superior_a' || row.market === 'medium_high_superior_b') && row.flag_actual,'high-bottom':(row.market=== 'high' || row.market === 'high_inferior' || row.market === 'high_inferior_a' || row.market === 'high_inferior_b' || row.market === 'high_superior' || row.market === 'high_superior_a' || row.market === 'high_superior_b') && row.flag_actual, 'border-bottom': !row.flag_actual, 'max-earning': row.flag_max_earning }">
                <span *ngIf="row.earning !== '-'">
                    {{row.earning| number : '1.0-0':'es'}} €
                </span>
                <span *ngIf="row.earning === '-'">
                    {{row.earning}}
                </span>
            </td>
        </tr>

    </tbody>
</table>
<div class="row" style="margin-top: 10px;">
    <div class="circles">
        <div class="rectangle {{market}}"></div>
        <span>
            Zona actual
        </span>
        <p title="Pocas observaciones" class="red" style="margin-bottom: 0;" [attr.data-letters]="'red'"></p>
        <span>
            Pocas observaciones
        </span>
        <div class="rectangle"></div>
        <span>
            Máxima ganancia actual
        </span>
    </div>
</div>