
<div class="row" style="margin:10px;" *ngIf="reqst === 0">
    <app-table-loader style="width: 100%;"></app-table-loader>
</div>
<div class="header-c" style="margin-bottom: 10px;margin-top: 10px;">
    <div class="select-type">
        <app-select-variable-px-opt></app-select-variable-px-opt>
    </div>
    Venta Promedio Diario
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Promedio de litros diarios vendidos por estación.
    </epy-tooltip>
    - Zona de Mercado
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Según el Indice de Precios, en que Zona o parte del Mercado te ubicas.<br>
        Baja: Indice <= 0,980 <br>
            Media Baja: 0,980 < Indice < 1,000 <br>
                Media Alta: 1,000 <= Indice < 1,020<br>
                    Alta: Indice => 1,020 <br>
    </epy-tooltip>
</div>
<div id="container-highcharts-180" style="width: 100%; margin-top: 1rem;" *ngIf="reqst === 1">
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
</div>