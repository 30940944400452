import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { Subscription, combineLatest } from 'rxjs';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

@Component({
  selector: 'app-chart-sales-quartil',
  templateUrl: './chart-sales-quartil.component.html',
  styleUrls: ['./chart-sales-quartil.component.scss']
})
export class ChartSalesQuartilComponent implements OnInit, OnDestroy {
  Highcharts = Highcharts;
  boxplotData;
  chartOptions;
  id;
  idStation;
  isRendering = false;
  labels = [];
  max;
  median;
  medianArray = [];
  reqst = 0;
  productType = 1;
  productType$!: Subscription;
  variable = 'sales'
  variable$!: Subscription;
  daysOptimumPx = 180;
  daysOptimumPx$!: Subscription;
  showSubdivision = 0;
  showSubdivision$!: Subscription;
  showSubsubdivision = 0;
  showSubsubdivision$!: Subscription;
  private subscriptions: Subscription = new Subscription();
  rol;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.subscriptions.add(
      combineLatest([
        this.sharedService.sharedVariablePxOpt,
        this.sharedService.sharedProduct,
        this.sharedService.sharedDaysOptimumPx,
        this.sharedService.sharedShowSubdivison,
        this.sharedService.sharedShowSubsubdivison
      ]).subscribe(([variable, productType, daysOptimumPx, showSubdivision, showSubsubdivision]) => {
        let shouldUpdate = false;

        if (this.variable !== variable) {
          this.variable = variable;
          shouldUpdate = true;
        }

        if (this.productType !== productType && !this.isRendering) {
          this.productType = productType;
          shouldUpdate = true;
        }

        if (this.daysOptimumPx !== daysOptimumPx) {
          this.daysOptimumPx = daysOptimumPx;
          shouldUpdate = true;
        }

        if (this.showSubdivision !== showSubdivision) {
          this.showSubdivision = showSubdivision;
          shouldUpdate = true;
        }

        if (this.showSubsubdivision !== showSubsubdivision) {
          this.showSubsubdivision = showSubsubdivision;
          shouldUpdate = true;
        }

        if (shouldUpdate && this.reqst > 0) {
          this.getChartSalesQuartil();
        }
      })
    );
  }

  ngOnInit(): void {
    if (!this.isRendering) {
      this.getChartSalesQuartil();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getChartSalesQuartil() {
    this.isRendering = true;
    this.reqst = 0;
    this.labels = [];
    this.medianArray = [];
    setTimeout(() => {
      this.gasprecioService.getChartSalesQuartil(this.productType, this.variable, this.daysOptimumPx, this.showSubdivision, this.showSubsubdivision).subscribe({
        next: (data: any) => {
          this.max = this.roundValues(data['max_sale'], 1000);
          const varText = this.variable === 'sales' ? 'Ventas' : 'Ganancia Bruta';
          let boxPlots = data['data'];
          this.labels = data['labels'];
          let box = {
            name: `${varText} Promedio Diario`,
            type: 'boxplot',
            data: [],
            tooltip: {
              headerFormat: '<em>Zona de Mercado: {point.key}</em><br/><br/>',
              pointFormatter: function () {
                if (varText === 'Ventas') {
                  return '<span style="color:' +
                    this.series.color + '">\u25CF</span> <b> ' +
                    this.series.name + '</b><br/>' +
                    'Mínimo: ' + (this.low.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                    '25% Cuartil: ' + (this.q1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                    'Mediana: ' + (this.median.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                    '75% Cuartil: ' + (this.q3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                    'Máximo: ' + (this.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>'
                } else {
                  return '<span style="color:' +
                    this.series.color + '">\u25CF</span> <b> ' +
                    this.series.name + '</b><br/>' +
                    'Mínimo: $' + (this.low.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                    '25% Cuartil: $' + (this.q1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                    'Mediana: $' + (this.median.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                    '75% Cuartil: $' + (this.q3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>' +
                    'Máximo: $' + (this.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' <br/>'
                }

              }
            },
            showInLegend: false
          };

          boxPlots.map((element: any, index: number) => {
            const name = data['labels'][index];
            switch (name) {
              case 'low':
              case 'low_inferior':
              case 'low_inferior_a':
              case 'low_inferior_b':
              case 'low_superior':
              case 'low_superior_a':
              case 'low_superior_b':
                box['data'].push({
                  low: element[0],
                  q1: element[1],
                  median: element[2],
                  q3: element[3],
                  high: element[4],
                  fillColor: 'rgba(229, 229, 229, 1)',
                  medianColor: 'black'
                });
                this.medianArray.push('black');
                break;
              case 'medium_low':
              case 'medium_low_inferior':
              case 'medium_low_inferior_a':
              case 'medium_low_inferior_b':
              case 'medium_low_superior':
              case 'medium_low_superior_a':
              case 'medium_low_superior_b':
                box['data'].push({
                  low: element[0],
                  q1: element[1],
                  median: element[2],
                  q3: element[3],
                  high: element[4],
                  fillColor: 'rgba(114, 114, 114, 1)',
                  medianColor: 'black'
                });
                this.medianArray.push('white');
                break;
              case 'medium_high':
              case 'medium_high_inferior':
              case 'medium_high_inferior_a':
              case 'medium_high_inferior_b':
              case 'medium_high_superior':
              case 'medium_high_superior_a':
              case 'medium_high_superior_b':
                box['data'].push({
                  low: element[0],
                  q1: element[1],
                  median: element[2],
                  q3: element[3],
                  high: element[4],
                  fillColor: 'rgba(0, 155, 211, .8)',
                  medianColor: 'black'
                });
                this.medianArray.push('white');
                break;
              case 'high':
              case 'high_inferior':
              case 'high_inferior_a':
              case 'high_inferior_b':
              case 'high_superior':
              case 'high_superior_a':
              case 'high_superior_b':
                box['data'].push({
                  low: element[0],
                  q1: element[1],
                  median: element[2],
                  q3: element[3],
                  high: element[4],
                  fillColor: 'rgba(130, 207, 255, .8)',
                  medianColor: 'black'
                });
                this.medianArray.push('black');
                break;
              default:
                break;
            }
          });

          var medianColors = this.medianArray;
          this.chartOptions = {
            credits: {
              enabled: false
            },
            exporting: {
              buttons: {
                  contextButton: {
                      align: 'right',
                      verticalAlign: 'top',
                      x: 0,
                      y: -30
                  }
              }
          },
            chart: {
              type: 'boxplot',
              spacingTop: 60,
              events: {
                load: function () {
                  var chart = this;
                  var points_180 = chart.series[0].points;
                  points_180.forEach(function (point_180, index) {
                    const median = point_180.median ? point_180.median.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
                    let textColor = medianColors[index];
                    const textVar = varText === 'Ventas' ? median + ' lts' : '$ ' + median;
                    let posText = varText === 'Ventas' && medianColors.length >= 6 ? 64 : varText === 'Ventas' && medianColors.length < 6 ? 60 : varText !== 'Ventas' && medianColors.length < 6 ? 54 : 58;
                    chart.renderer.text(textVar, point_180.plotX + posText, point_180.medianPlot + 70)
                      .css({
                        color: textColor,
                        fontSize: '11px',
                        fontWeight: 'bold'
                      })
                      .attr({
                        zIndex: 5
                      })
                      .add();
                  });
                }
              }
            },
            title: {
              text: ''
            },
            xAxis: {
              categories: this.labels,
              title: {
                text: ''
              },
              labels: {
                enabled: false
              }
            },
            yAxis: {
              title: {
                text: ''
              },
              labels: {
                formatter: function () {
                  let value = this.value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  if (varText === 'Ventas') {
                    return value + ' lts.';
                  } else {
                    return '$ ' + value;
                  }
                }
              }
            },
            plotOptions: {
              boxplot: {
                lineWidth: 1,
                color: 'black',
                medianWidth: 1.5,
                pointWidth: medianColors.length >= 6 ? 76 : 90,
                groupPadding: 1,
                whiskerLength: medianColors.length >= 6 ? 84 : 98,
                dataLabels: {
                  enabled: true,
                  y: 0
              }
              }
            },
            tooltip: {
              boxplot: {
                enabled: true
              },
              line: {
                enabled: false
              }
            },
            series: [box]
          };
        },
        complete: () => {
          this.isRendering = false;
          this.reqst = 1;
          setTimeout(() => {
            const chart = Highcharts.chart('container-highcharts-180', this.chartOptions);
          }, 10);
        }
      });
    }, 300);
  }

  roundValues(value, round) {
    const result = Math.round(value / round) * round;
    return result;
  }
}