import * as moment from 'moment-timezone';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';


@Component({
  selector: 'app-sales-chart',
  templateUrl: './sales-chart.component.html',
  styleUrls: ['./sales-chart.component.scss']
})
export class SalesChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @Input() section: any = false;
  adjusted;
  changeModal$!: Subscription;
  changeModal;
  forecast;
  productType = 1;
  productType$!: Subscription;
  reqst = 0;
  sales: any;
  stationId$!: Subscription;
  stationId: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: 'Ventas - Venta Ajustada - Venta Pronosticada',
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        format: '{value:,.0f} lts.'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: 'Ventas - Venta Ajustada - Venta Pronosticada',
      sourceWidth: 1200,
      sourceHeight: 800
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -16
      }
    },
    series: []
  };

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            callback(value) {
              value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              return value + ' lts.';
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, details: any) => {
          const dataset = details.datasets[tooltipItem.datasetIndex];
          const value: any = tooltipItem.yLabel;
          return dataset.label + ': ' + this.numberWithCommas(value) + ' lts.';
        },
        footer: () => {
          return 'Click aquí para ver el detalle del día';
        }
      }
    },
    elements: {
      line: {
        fill: false
      }
    },
    annotation: {},
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  public lineChartColors: Color[] = [];

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProduct.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartSales();
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartSales();
    }
   }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  getChartSales() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }
    this.gasprecioService.getChartSales(this.stationId).subscribe((data: any) => {
      this.sales = data['record'];
      this.adjusted = data['adjusted'];
      this.forecast = data['forecast'];
      this.setData();
      this.reqst = 2;
    }, err => {
      console.log(err);
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(String(this.productType))]);
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colors[0]);
    const colorGold = 'rgba(212, 175, 55, 1)';
    const forecastData = this.forecast.map((v: any) => +v['product_' + this.productType] || 0);
    const hasNonZeroValue = forecastData.some(value => value !== 0);

    const lineChartData: any = [
      {
        name: 'Ventas',
        data: this.sales.map((v: any) => {
          return v['product_' + this.productType] === null ? null : Number(v['product_' + this.productType])
        }),
        lineWidth: 2,
        color: color,
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartClicked(event['point']['index'])
          }
        }
      },
      {
        name: 'Venta Ajustada',
        data: this.adjusted.map((v: any) => {
          return v['product_' + this.productType] === null ? null : Number(v['product_' + this.productType])
        }),
        lineWidth: 2,
        color: colorLight,
        dashStyle: 'ShortDot',
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartClicked(event['point']['index'])
          }
        }
      },
      {
        name: 'Venta Pronosticada',
        data: hasNonZeroValue ? forecastData : [],
        lineWidth: 2,
        color: colorGold,
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts. </b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartClicked(event['point']['index'])
          }
        }
      }
    ];

    const lineXaxisLegend = [];
    this.sales.forEach((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  chartClicked(e: any) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const date = this.sales[clickedElementIndex].date;
        const name = this.sales[clickedElementIndex].name;
        this.changeDataTable(date, name);
        this.openModal();
      }
    }
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment(date).tz('Europe/Madrid').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }
}
